import { FC, useEffect } from "react";

import { CasesTable, Filter, CasesFooter, CasesMobileTable } from "components/cases";
import { useAppDispatch, useAppSelector } from "store";
import { getCases } from "store/cases/cases.thunks";
import { getUserSelector } from "store/user/user.selector";

const CasesPage: FC = () => {
  const dispatch = useAppDispatch();
  const { isMobile } = useAppSelector(getUserSelector);

  useEffect(() => {
    dispatch(getCases())
  }, [])

  return (
    <div className={ `default-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <Filter />
      { isMobile ? <CasesMobileTable /> : <CasesTable /> }
      <CasesFooter />
    </div>
  )
}

export default CasesPage;

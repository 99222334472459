import { message } from "antd";

import { TAppDispatch, TRootState } from "store";
import {
  getContractEquipmentsFail,
  getContractEquipmentsRequest,
  getContractEquipmentsSuccess,
  getContractsFail,
  getContractsRequest,
  getContractsSuccess,
  getCurrentContractFail,
  getCurrentContractRequest,
  getCurrentContractSuccess, getExtraContractEquipmentsFail, getExtraContractEquipmentsRequest, getExtraContractEquipmentsSuccess,
} from "./contracts.actions";
import api from "services/api.service";
import { IContractsState } from "./contracts.types";
import { extraLimit, extraPortion } from "services/data.service";

export const getContracts = () => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  try {
    dispatch(getContractsRequest())

    const { filter, pagination, sort } = getState().contracts;

    const response = await api.getContracts(filter, pagination, sort);

    dispatch(getContractsSuccess(
      response.data.results.map((con) => ({
        id: con.id,
        identifier: con.identifier,
        date_start_from: con.date_start_from,
        date_valid_until: con.date_valid_until,
        customer: con.customer.latin_name,
        warning_reason: con.warning_reason,
        alias: {
          id: con.alias[ 0 ]?.id || 0,
          value: con.alias[ 0 ]?.value || "",
        },
        is_prolonged: con.is_prolonged,
        is_active: con.is_active,
      })),
      response.data.count,
    ))
  } catch (e: any) {
    dispatch(getContractsFail())
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getContract = (id: string) => async (dispatch: TAppDispatch) => {
  dispatch(getCurrentContractRequest())
  try {
    const response = await api.getContract(id);

    dispatch(getCurrentContractSuccess(response.data));
  } catch (e: any) {
    dispatch(getCurrentContractFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getContractEquipments = (id?: number) => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  dispatch(getContractEquipmentsRequest());
  try {
    const { currentContract, equipmentsFilter, equipmentsPagination, equipmentsSort }: IContractsState = getState().contracts;
    const response = await api.getContractEquipments(
      id || currentContract?.id || 0,
      equipmentsFilter,
      equipmentsSort,
      extraPortion,
      (equipmentsPagination.page - 1) * extraLimit,
    );

    dispatch(getContractEquipmentsSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getContractEquipmentsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

export const getExtraContractEquipments = (id?: number) => async (dispatch: TAppDispatch, getState: () => TRootState) => {
  const {
    currentContract,
    equipmentsFilter,
    equipmentsPagination,
    equipmentsSort,
    isExtraEquipmentsLoading,
    equipments,
  }: IContractsState = getState().contracts;
  const offset = (equipmentsPagination.page - 1) * extraLimit + equipments.length;

  if (isExtraEquipmentsLoading || equipments.length >= extraLimit || offset === equipmentsPagination.count) {
    return;
  }

  try {
    dispatch(getExtraContractEquipmentsRequest());

    const response = await api.getContractEquipments(
      id || currentContract?.id || 0,
      equipmentsFilter,
      equipmentsSort,
      extraPortion,
      offset,
    )

    dispatch(getExtraContractEquipmentsSuccess(response.data.results, response.data.count));
  } catch (e: any) {
    dispatch(getExtraContractEquipmentsFail());
    message.error(e.response.data.error || e.error || "Something went wrong!");
  }
}

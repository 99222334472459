import { FC } from "react";

import { Header } from "antd/es/layout/layout";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";

import { RouteLinks } from "services/router.service";
import appLogo from "data/icons/SDP-logo.svg";
import loginIcon from "data/icons/login.svg";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { authorizedPages } from "services/data.service";
import { Notifications, UserMenu } from "components/common";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { CreateCaseForm } from "components/cases";

import "./TopBar.scss";

const TopBar: FC = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  const { isAuthorized, isMobile } = useAppSelector(getUserSelector);
  const { currentContract } = useAppSelector(getContractsSelector);

  const extraLinks = [
    {
      value: RouteLinks.PROTECTED.DOWNLOAD_ROUTES.ADD_FILE,
      title: "Add File",
    },
    {
      value: RouteLinks.PROTECTED.DOWNLOAD_ROUTES.FILE,
      title: "File",
    },
    {
      value: RouteLinks.PROTECTED.CASES.CASE,
      title: "Case",
    },
    {
      value: RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT,
      title: currentContract ? currentContract?.identifier + " - " + currentContract?.customer.latin_name : "Loading...",
    },
  ]

  return (
    <Header className={ `app-header ${ isMobile ? "mobile" : "" }` }>
      { isAuthorized ? (
        <>
          { !isMobile ? (
            <p className="page-title">
              { authorizedPages.find((page) => page.link === loc.pathname)?.label
                || extraLinks.find((link) => loc.pathname.indexOf(link.value) !== -1)?.title }
            </p>
          ) : (
            <>
              <UserMenu />
              <Notifications />
            </>
          ) }
          <CreateCaseForm />
          { !isMobile && (
            <>
              <Notifications />
              <UserMenu />
            </>
          ) }
        </>
        ) : (
        <>
          <img src={ appLogo } alt="" className="app-logo" />
          { (loc.pathname !== RouteLinks.AUTH && loc.pathname !== RouteLinks.SIGN_UP) && (
            <>
              <NavLink className="login-button" to={ RouteLinks.AUTH }>
                <img src={ loginIcon } alt="" />
                Log in
              </NavLink>
              <Button
                className="sign-up-button"
                type="primary"
                onClick={ () => navigate(RouteLinks.SIGN_UP) }
              >
                Sign Up
              </Button>
            </>
          ) }
        </>
      ) }
    </Header>
  )
}

export default TopBar;

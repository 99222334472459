import { FC, useState } from "react";

import { Button, Form, Input } from "antd";
import { NavLink, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { authorizeUser } from "store/user/user.thunks";
import { getUserSelector } from "store/user/user.selector";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";

import "./AuthorizationPage.scss";

const AuthorizationPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthorizationLoading, isMobile } = useAppSelector(getUserSelector);

  const [ authorizationError, setAuthorizationError ] = useState("");

  const handleSubmit = (values: { email: string, password: string }) => {
    dispatch(authorizeUser(values.email, values.password, (error: string) => {
      setAuthorizationError(error);
    }, () => {
      navigate(RouteLinks.PROTECTED.CASES.MAIN);
    }));
  }
  const handleSubmitFailed = () => {}

  return (
    <div className={ `authorization-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <Form
        name="basic"
        className="login-form"
        layout="vertical"
        initialValues={ { remember: true } }
        onFinish={ handleSubmit }
        onFinishFailed={ handleSubmitFailed }
        autoComplete="off"
      >
        { authorizationError !== "" && (
          <div className="login-error">{ authorizationError }</div>
        ) }
        <Form.Item
          label="Email"
          name="email"
          rules={ [ { required: true, message: "Please input your email!" } ] }
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={ [ { required: true, message: "Please input your password!" } ] }
        >
          <Input.Password/>
        </Form.Item>
        <Form.Item>
          <Button onClick={ () => navigate(RouteLinks.RESET_PASSWORD) } type="link" name="Forgot your password?">Forgot your password?</Button>
        </Form.Item>
        <Form.Item className="mg-btn-0">
          <Button className="big-btn" type="primary" htmlType="submit">
            <Loading isLoading={ isAuthorizationLoading } height={ 24 } width={ 44 }>
              Log in
            </Loading>
          </Button>
        </Form.Item>
      </Form>
      <p className="bottom-description">Don{ "'" }t have an account yet? Sign up <NavLink to={ RouteLinks.SIGN_UP }>here</NavLink> or navigate the <NavLink to={ RouteLinks.MAIN }>main page</NavLink>.</p>
    </div>
  )
}

export default AuthorizationPage;

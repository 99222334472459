import { ChangeEvent, FC, useEffect, useMemo } from "react";

import { Input, Tabs, TabsProps } from "antd";
import debounce from "lodash.debounce";

import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { updateContractsFilter, updateContractsPagination, updateContractsSort } from "store/contracts/contracts.actions";
import searchIcon from "data/icons/search.svg";
import { getContracts } from "store/contracts/contracts.thunks";
import { initialState } from "store/contracts/contracts.reducer";

import "./ContractsHeader.scss";

const tabs: TabsProps[ "items" ] = [
  {
    key: "service",
    label: "Service",
  },
  {
    key: "supply",
    label: "Warranty",
  },
]

const ContractsHeader: FC = () => {
  const dispatch = useAppDispatch();
  const { filter, pagination } = useAppSelector(getContractsSelector);

  useEffect(() => {
    return () => {
      dispatch(updateContractsFilter(initialState.filter));
      dispatch(updateContractsSort(initialState.sort));
      dispatch(updateContractsPagination(initialState.pagination.page, initialState.pagination.rowsPerPage));
    }
  }, [])

  const handleChangeType = (type: string) => {
    dispatch(updateContractsFilter({
      ...filter,
      type: type as "service" | "supply",
    }))
    dispatch(updateContractsPagination(1, pagination.rowsPerPage));
    dispatch(getContracts());
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateContractsFilter({
      ...filter,
      search: e.target.value,
    }))
    dispatch(updateContractsPagination(1, pagination.rowsPerPage));
  }

  const updateInfo = () => {
    dispatch(getContracts());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <div className="contracts-header-wrapper">
      <Tabs items={ tabs } activeKey={ filter.type } onChange={ handleChangeType } />
      <Input
        value={ filter.search }
        prefix={ <img src={ searchIcon } alt="" /> }
        placeholder="Search"
        onChange={ (e) => {
          handleChangeSearch(e);
          debouncedResults()
        } }
        className="search-input"
      />
    </div>
  )
}

export default ContractsHeader;

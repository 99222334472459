import { FC, useState } from "react";

import { Button, Modal } from "antd";

import { CaseForm } from "components/common";

import "./CreateCaseForm.scss";

interface IProps {
  serial_number?: string;
  id?: number;
}

const CreateCaseForm: FC<IProps> = ({ serial_number = "", id }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <Button className="create-case-button" type="primary" onClick={ () => setIsOpen(true) }>
        Open case
      </Button>
      <Modal
        className="create-case-form"
        open={ isOpen }
        onCancel={ () => setIsOpen(false) }
        footer={ null }
      >
        { isOpen && <CaseForm afterEvent={ () => setIsOpen(false) } serial_number={ serial_number } id={ id } /> }
      </Modal>
    </>
  )
}

export default CreateCaseForm;

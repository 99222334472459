import { FC, useEffect, useState } from "react";

import { Button, Form, Input, message, Select, Switch } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import backIcon from "data/icons/arrow-left.svg";
import { RouteLinks } from "services/router.service";
import deleteIcon from "data/icons/trash.svg";
import { useAppDispatch, useAppSelector } from "store";
import { IDownload } from "store/downloads/downloads.types";
import api from "services/api.service";
import { Loading } from "components/common";
import { getUserSelector } from "store/user/user.selector";

import "./FileForm.scss";

const FileForm: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fileId } = useParams();
  const [ form ] = Form.useForm();
  const dispatch = useAppDispatch();

  const { isAdmin } = useAppSelector(getUserSelector);

  const [ isDownloadUploading, setIsDownloadUploading ] = useState(false);
  const [ isFileUploading, setIsFileUploading ] = useState(false);
  const [ isFileRemoving, setIsFileRemoving ] = useState(false);
  const [ clients, setClients ] = useState<{ value: string, label: string }[]>([]);

  const [ values, setValues ] = useState<IDownload>({
    customers: [],
    date: "",
    description: "",
    file: null,
    id: 0,
    is_shared_to_all: false,
    link: "",
    provider: 0,
    source_size: 0,
    title: "",
  });

  useEffect(() => {
    if (fileId) {
      const getDownload = async () => {
        try {
          setIsDownloadUploading(true);
          const response = await api.getDownload(fileId);

          setValues(response.data);
          form.setFieldsValue({ ...response.data, customers: response.data.customers.map((item) => item.toString()) });
          setIsDownloadUploading(false);
        } catch (e: any) {
          message.error(e.response.data.error || e.error || "Something went wrong!");
          setIsDownloadUploading(false);
        }
      }
      isAdmin && getClients("");
      getDownload();
    }
  }, [])

  const getClients = async (search: string) => {
    try {
      const response = await api.getCustomers(search);

      setClients([ ...response.data.results.map((res) => ({ value: res.id.toString(), label: res.latin_name })) ]);
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const handleChangeForm = (value: Partial<IDownload>) => {
    setValues({
      ...values,
      ...value,
    })
  }

  const handleAddFile = async (file: File) => {
    setIsFileUploading(true)
    const response = await api.uploadFile(file);
    setValues({
      ...values,
      file: file,
      source_size: file.size,
      title: file.name,
    })
    setIsFileUploading(false);
  }

  const handleCompleteForm = async () => {
    try {
      setIsDownloadUploading(true);

      console.log(location.pathname);

      if (location.pathname === RouteLinks.PROTECTED.DOWNLOAD_ROUTES.ADD_FILE) {
        const response = await api.createDownload(values);

        handleChangeForm({ id: response.data.id });
      } else {
        await api.updateDownload(values);
      }

      setIsDownloadUploading(false);
    } catch (e: any) {
      setIsDownloadUploading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const handleRemoveFile = async () => {
    try {
      setIsFileRemoving(true);

      await api.removeDownload(values.id);

      setIsFileRemoving(false);
      navigate(RouteLinks.PROTECTED.DOWNLOAD_ROUTES.MAIN);
    } catch (e: any) {
      setIsFileRemoving(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  return (
    <div className="add-file-form-wrapper">
      <Button className="back-button" type="link" onClick={ () => navigate(RouteLinks.PROTECTED.DOWNLOAD_ROUTES.MAIN) }>
        <img src={ backIcon } alt="" />
        Back
      </Button>
      <Form
        className="add-file-form"
        layout="vertical"
        form={ form }
        onValuesChange={ (changedValues) => {
          handleChangeForm(changedValues);
        } }
        onFinish={ handleCompleteForm }
      >
        <Form.Item
          label="Title"
          name="title"
          rules={ [ { required: true, message: "Please input title!" } ] }
        >
          <Input />
        </Form.Item>
        {/*<Form.Item*/}
        {/*  name="file_type"*/}
        {/*>*/}
        {/*  <Radio.Group>*/}
        {/*    <Radio value="file">File</Radio>*/}
        {/*    <Radio value="link">Link</Radio>*/}
        {/*  </Radio.Group>*/}
        {/*</Form.Item>*/}
        {/*{ values.link === "" ? (*/}
        {/*  <>*/}
        {/*    { values.title === "" ? (*/}
        {/*      <Form.Item className="upload-file-wrapper">*/}
        {/*        <Loading isLoading={ isFileUploading } isColored height={ 150 } width={ 350 }>*/}
        {/*          <Upload.Dragger*/}
        {/*            className="add-file-form-drag"*/}
        {/*            multiple={ false }*/}
        {/*            onChange={ (e) => {*/}
        {/*              if (e.fileList.length === 0) {*/}
        {/*                handleChangeForm({*/}
        {/*                  file: null,*/}
        {/*                  source_size: 0,*/}
        {/*                })*/}
        {/*              } else if (e.event) {*/}
        {/*                handleAddFile(e.file as unknown as File);*/}
        {/*              }*/}
        {/*            } }*/}
        {/*          >*/}
        {/*            <img src={ cloudIcon } alt="" />*/}
        {/*            <p className="upload-title">Click or drag file to this area to upload</p>*/}
        {/*          </Upload.Dragger>*/}
        {/*        </Loading>*/}
        {/*      </Form.Item>*/}
        {/*    ) : (*/}
        {/*      <div className="uploaded-file">*/}
        {/*        <FileIcon size="small" fileType="file" fileName={ values.title } />*/}
        {/*        <div className="uploaded-file-info">*/}
        {/*          <p className="uploaded-file-name">{ values.title }</p>*/}
        {/*          <div className="uploaded-file-size">*/}
        {/*            <p className="uploaded-file-title">Filesize:</p>*/}
        {/*            <p className="uploaded-file-value">{ getFileSizeTitle( values.source_size ) }</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <Button*/}
        {/*          type="primary"*/}
        {/*          className="remove-file-button"*/}
        {/*          onClick={ () => handleChangeForm({*/}
        {/*            file: null,*/}
        {/*            source_size: 0,*/}
        {/*          }) }*/}
        {/*        >*/}
        {/*          Remove*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*    ) }*/}
        {/*  </>*/}
        {/*) : (*/}
          <Form.Item
            name="link"
            label="Link"
            rules={ [ { required: true, message: "Please input download link!" } ] }
          >
            <Input />
          </Form.Item>
        {/*  ) }*/}
        <Form.Item className={ `switch-info-wrapper ${ values.is_shared_to_all ? "" : "off" }` }>
          <Switch
            size="small"
            checked={ values.is_shared_to_all }
            onChange={ (value) => {
              handleChangeForm({ customers: [], is_shared_to_all: value })
              value || form.setFieldValue("customers", []);
            } }
          />
          <p className="switch-info">Share to all clients</p>
        </Form.Item>
        { !values.is_shared_to_all && (
          <Form.Item
            name="customers"
            label="Select clients"
            rules={ [ { required: true, message: "Please input at least one client!" } ] }
          >
            <Select
              mode="multiple"
              options={ clients }
              onSearch={ (value) => getClients(value) }
              onFocus={ () => getClients("") }
              filterOption={ false }
              onChange={ () => getClients("") }
            />
          </Form.Item>
        ) }
        <Form.Item
          name="description"
          label="Description"
          rules={ [ { required: true, message: "Please input description!" } ] }
        >
          <Input.TextArea
            autoSize={ false }
            style={ { resize: "none", height: "78px" } }
          />
        </Form.Item>
        <div className="buttons-wrapper">
          <Button
            htmlType="submit"
            type="primary"
          >
            <Loading isLoading={ isDownloadUploading } width={ 48.391 }>
              { values.id === 0 ? "Create" : "Update" }
            </Loading>
          </Button>
          <Button onClick={ () => navigate(RouteLinks.PROTECTED.DOWNLOAD_ROUTES.MAIN) }>
            Cancel
          </Button>
          { values.id !== 0 && (
            <Button type="text" onClick={ handleRemoveFile }>
              <Loading isLoading={ isFileRemoving } isColored width={ 65 }>
                <img src={ deleteIcon } alt="" />
                Delete
              </Loading>
            </Button>
          ) }
        </div>
      </Form>
    </div>
  )
}

export default FileForm;

import { FC, useEffect } from "react";

import { UserManagementFilter, UserManagementPagination, UserManagementTable } from "components/userManagement";
import { useAppDispatch } from "store";
import { getClients } from "store/userManagement/userManagement.thunks";

const UserManagementPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getClients());
  }, [])

  return (
    <div className="default-page-wrapper">
      <UserManagementFilter />
      <UserManagementTable />
      <UserManagementPagination />
    </div>
  )
}

export default UserManagementPage;

import { FC } from "react";

import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import appLogo from "data/icons/SDP-logo.svg";
import { authorizedPages } from "services/data.service";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { RouteLinks } from "services/router.service";
import { getUserManagementSelector } from "store/userManagement/userManagement.selector";

import "./NavBar.scss";

const NavBar: FC = () => {
  const { isMobile, isAdmin } = useAppSelector(getUserSelector);
  const { newClientsCount } = useAppSelector(getUserManagementSelector);
  const loc = useLocation();
  const navigate = useNavigate();

  return !isMobile ? (
    <Layout.Sider className="navbar-wrapper">
      <img src={ appLogo } alt="" className="navbar-label" />
      <div className="nav-wrapper">
        { authorizedPages.filter((page) => isAdmin
          || (page.link !== RouteLinks.PROTECTED.USER_MANAGEMENT
            && page.link !== RouteLinks.PROTECTED.STORAGE
            && page.link !== RouteLinks.PROTECTED.PRODUCTS))
          .map((link) => (
          <>
            { (link.link === RouteLinks.PROTECTED.PRODUCTS || link.link === RouteLinks.PROTECTED.STORAGE) && (
              <div className="divider" />
            ) }
            <div
              className={ `nav-item ${ loc.pathname.indexOf(link.link) !== -1 ? "active" : "" }` }
              key={ link.link }
              onClick={ () => navigate(link.link) }
            >
              <img src={ loc.pathname.indexOf(link.link) !== -1 ? link.blueIcon : link.icon } alt="" />
              { link.label }
              { link.link === RouteLinks.PROTECTED.USER_MANAGEMENT && newClientsCount > 0 && (
                <p className="clients-count-value">{ newClientsCount }</p>
              ) }
            </div>
          </>
        )) }
      </div>
    </Layout.Sider>
  ) : (
    <Layout.Footer className="footer-wrapper">
      <div className="footer-nav-wrapper">
        { authorizedPages.filter((page) => page.label === "Cases").map((page) => (
          <div
            className={ `nav-item ${ loc.pathname.indexOf(page.link) !== -1 ? "active" : "" }` }
            key={ page.link }
            onClick={ () => navigate(page.link) }
          >
            <img src={ loc.pathname.indexOf(page.link) !== -1 ? page.darkIcon : page.grayIcon } alt="" />
            { page.link === RouteLinks.PROTECTED.LIST_EQUIPMENT ? "Equipment" : page.label }
          </div>
        )) }
      </div>
    </Layout.Footer>
  )
}

export default NavBar;

import axios from "axios"

import { INotification, IUser, IUserWithToken } from "store/user/user.types";
import { ICase, ICaseEquipment, ICasesFilter } from "store/cases/cases.types";
import { IPagination, ISorting } from "./interface.service";
import { IDownload, IDownloadsFilter } from "store/downloads/downloads.types";
import { IEquipmentSource, IListEquipmentFilter } from "store/listEquipment/listEquipment.types";
import {
  IContract,
  IContractEquipment,
  IContractEquipmentsFilter,
  IContractsFilter, ICustomerSource,
  IShortContractorSource,
} from "store/contracts/contracts.types";
import { IClient, IUserManagementFilter } from "store/userManagement/userManagement.types";
import { formatDateForApi } from "./data.service";
import { IStorageFilter, IStorageItem } from "store/storage/storage.types";
import { IProduct, IProductsFilter } from "store/products/products.types";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

const ApiService = {
  getConfig: function () {
    return {
      headers: {
        "Authorization": localStorage.getItem("AUTH_TOKEN") ? "JWT " + localStorage.getItem("AUTH_TOKEN") : undefined,
      },
    }
  },
  authorizeUser: async function (email: string, password: string): Promise<{ data: IUserWithToken }> {
    return await api.post("/user/token/", { email, password })
  },
  resetPassword: async function (email: string): Promise<void> {
    return await api.post("/user/reset-password/", { email })
  },
  changePassword: async function (password: string, token: string): Promise<void> {
    return await api.post("/user/reset-password/confirm/", { password, token });
  },
  registerUser: async function (
    first_name: string, last_name: string, email: string, phone_number: string, password: string,
  ): Promise<void> {
    return await api.post("/user/sign-up/", { first_name, last_name, email, phone_number, password });
  },
  getUserData: async function (): Promise<{ data: IUser }> {
    return api.get("/user/info/", this.getConfig());
  },
  getCases: async function (filter: ICasesFilter, pagination: IPagination, sort: ISorting):
    Promise<{ data: { results: ICase[], count: number } }> {
    return await api.get("/cases/" +
      `?limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }` +
      `&customer=${ filter.client === "all" ? "" : filter.client }` +
      `&search=${ filter.search }` +
      `&status=${ filter.type === "all" ? "" : filter.type }` +
      `&created_at=${ formatDateForApi(filter.date_from) }` +
      `&closed_at=${ formatDateForApi(filter.date_to) }` +
      `&severity=${ filter.severity === null ? "" : filter.severity }`,
      this.getConfig(),
    )
  },
  getDownloads: async function (
    filter: IDownloadsFilter,
    pagination: IPagination,
  ): Promise<{ data: { results: IDownload[], count: number } }> {
    return await api.get(`/shared-sources/` +
      `?limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }` +
      `&search=${ filter.search }`,
      this.getConfig(),
    )
  },
  createDownload: async function (download: IDownload): Promise<{ data: { id: number } }> {
    return await api.post("/shared-source/", {
      title: download.title,
      description: download.description,
      link: download.link,
      customers: download.customers,
      is_shared_to_all: download.is_shared_to_all,
    }, this.getConfig());
  },
  updateDownload: async function (download: IDownload): Promise<void> {
    return await api.put(`/shared-source/${ download.id }/`, {
      title: download.title,
      description: download.description,
      link: download.link,
      customers: download.customers,
      is_shared_to_all: download.is_shared_to_all,
    }, this.getConfig());
  },
  removeDownload: async function (id: number): Promise<void> {
    return await api.delete(`/shared-source/${ id }/`, this.getConfig());
  },
  getDownload: async function (id: string): Promise<{ data: IDownload }> {
    return await api.get(`/shared-source/${ id }/`, this.getConfig());
  },
  getEquipments: async function (filter: IListEquipmentFilter, sort: ISorting, limit: number, offset: number):
    Promise<{ data: { results: IEquipmentSource[], count: number } }> {
    return await api.get(
      `/equipment/` +
      `?limit=${ limit }` +
      `&offset=${ offset }` +
      `&search=${ filter.search }` +
      `&subset=${ filter.subset }` +
      `&customer=${ (filter.client === "all" || filter.client === undefined) ? "" : filter.client }` +
      `&date_begin=${ formatDateForApi(filter.date_begin) }` +
      `&date_end=${ formatDateForApi(filter.date_end) }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }`,
      this.getConfig(),
    );
  },
  getContracts: async function (filter: IContractsFilter, pagination: IPagination, sort: ISorting):
    Promise<{ data: { results: IShortContractorSource[], count: number } }> {
    return await api.get(`/contracts/` +
      `?search=${ filter.search }` +
      `&type=${ filter.type }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }` +
      `&limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }`,
      this.getConfig(),
    )
  },
  getContract: async function (id: string): Promise<{ data: IContract }> {
    return await api.get(`/contracts/${ id }/`, this.getConfig());
  },
  getContractEquipments: async function (
    id: number, filter: IContractEquipmentsFilter, sort: ISorting, limit: number, offset: number,
  ) : Promise<{ data: { results: IContractEquipment[], count: number } }> {
    return await api.get(`/contracts/${ id }/equipment/` +
      `?category=${ filter.type === "all" ? "" : filter.type }` +
      `&search=${ filter.search }` +
      `&sort_field=${ sort.column }` +
      `&sort_direction=${ sort.direction }` +
      `&limit=${ limit }` +
      `&offset=${ offset }`,
      this.getConfig(),
    )
  },
  getNotifications: async function (): Promise<{ data: { results: INotification[] } }> {
    return await api.get("/notifications/", this.getConfig());
  },
  clearNotifications: async function (): Promise<void> {
    return await api.get("/notifications/viewed/", this.getConfig());
  },
  getNewClientsCount: async function (): Promise<{ data: number }> {
    return await api.get("/users/new/count/", this.getConfig());
  },
  getClients: async function (filter: IUserManagementFilter, pagination: IPagination, sort: ISorting)
    : Promise<{ data: { results: IClient[], count: number } }> {
    return await api.get(`/users/` +
      `?is_verified${ filter.type === "blocked" ? "=False" : `__isnull=${ filter.type === "new" ? "True" : "False" }` }` +
      `&page=${ pagination.page }` +
      // `&sort_field=${ sort.column }` +
      // `&sort_direction=${ sort.direction }` +
      `&limit=${ pagination.rowsPerPage }` +
      `&offset=${ (pagination.page - 1) * pagination.rowsPerPage }`, this.getConfig());
  },
  editClient: async function (client: IClient): Promise<void> {
    return await api.patch(`/users/${ client.id }/`, { phone_number: client.phone_number, role: client.role, customer: client.customer && +client.customer }, this.getConfig());
  },
  acceptClient: async function (clientId: string): Promise<void> {
    return await api.post("/users/verify/", { user_id: clientId, is_verified: true }, this.getConfig());
  },
  blockClient: async function (clientId: string): Promise<void> {
    return await api.post("/users/verify/", { user_id: clientId, is_verified: false }, this.getConfig());
  },
  editPhone: async function (phone: string): Promise<void> {
    return await api.patch("/user/info/", { phone_number: phone }, this.getConfig());
  },
  updateContractAlias: async function (aliasId: number, value: string): Promise<void> {
    return await api.patch(`/contracts/alias/${ aliasId }/`, { value }, this.getConfig());
  },
  updateContractInformation: async function (id: number, information: string): Promise<void> {
    return await api.patch(`/contracts/${ id }/`, { information }, this.getConfig());
  },
  getEquipmentForCase: async function (serial_number: string, id?: number): Promise<{ data: ICaseEquipment | null }> {
    return await api.get(`/equipment/by_sn_id/?sn=${ serial_number }&id=${ id || "" }`, this.getConfig());

  },
  openCase: async function (case_for_open: Partial<ICase>): Promise<void> {
    let result: any = {
      author_email: case_for_open.author_email,
      subject: case_for_open.subject,
      attachments: case_for_open.attachments,
      text: case_for_open.text,
      author_name: case_for_open.author_name,
      severity: case_for_open.severity,
      email_cc: case_for_open.email_cc,
    }

    if (case_for_open.serial_number && case_for_open.serial_number !== "") {
      result = { ...result, serial_number: case_for_open.serial_number };
    }

    if (case_for_open.author_phone_number && case_for_open.author_phone_number !== "") {
      result = { ...result, author_phone_number: case_for_open.author_phone_number };
    }

    return await api.post("/cases/create/", result, this.getConfig());
  },
  closeCase: async function (id: number): Promise<void> {
    return await api.get(`/cases/${ id }/close/`, this.getConfig());
  },
  editCaseResolution: async function (id: number, text: string): Promise<void> {
    return await api.patch(`/cases/resolutions/${ id }/`, { text }, this.getConfig());
  },
  getContractEquipmentCategories: async function (): Promise<{ data: { results: { id: number, name: string }[] } }> {
    return await api.get("/product/categories/", this.getConfig());
  },
  getCustomers: async function (search: string): Promise<{ data: { results: ICustomerSource[] } }> {
    return await api.get(`/customers/?search=${ search }`, this.getConfig());
  },
  uploadFile: async function (file: File): Promise<{ id: number }> {
    const startResponse = await api.post("/file/upload/direct/start/", { file_name: file.name, file_type: file.name.substring(file.name.lastIndexOf(".")) }, this.getConfig());
    const postData = new FormData();

    for (const key in startResponse.data.fields) {
      postData.append(key, startResponse.data.fields[ key ]);
    }

    postData.append("file", file);

    await api.post(
      startResponse.data.url,
      postData,
      {},
    );

    await api.post("/file/upload/direct/finish/", { file_id: startResponse.data.id }, this.getConfig());
    return { id: startResponse.data.id };
  },
  getStorage: async function (filter: IStorageFilter, limit: number, offset: number):
    Promise<{ data: { results: IStorageItem[], count: number } }> {
    return await api.get("/storage/equipment/" +
      `?search=${ filter.search }` +
      `&limit=${ limit }` +
      `&offset=${ offset }`, this.getConfig())
  },
  getCase: async function (id: string): Promise<{ data: ICase }> {
    return await api.get("/cases/" + id + "/", this.getConfig());
  },
  getProducts: async function (filter: IProductsFilter, limit: number, offset: number):
    Promise<{ data: { results: IProduct[], count: number } }> {
    return await api.get("/products/" +
      `?limit=${ limit }` +
      `&offset=${ offset }` +
      `&category=${ filter.category === null ? "" : filter.category }` +
      `&search=${ filter.search }`, this.getConfig());
  },
  addProduct: async function (product: IProduct): Promise<void> {
    return await api.post("/products/", {
      product_number: product.product_number,
      category: product.category,
      description: product.description,
    }, this.getConfig());
  },
  editProduct: async function (product: IProduct): Promise<void> {
    return await api.patch(`/products/${ product.id }/`, product, this.getConfig());
  },
}

export default ApiService;

import { Reducer } from "react";

import { ICasesState, TCasesAction } from "./cases.types";
import { ActionTypes } from "./cases.actions";

export const initialState: ICasesState = {
  cases: [],
  isCasesLoading: false,
  currentCase: null,
  isCurrentCaseLoading: false,
  filter: {
    search: "",
    date_from: "",
    date_to: "",
    client: "all",
    severity: null,
    type: "all",
  },
  pagination: {
    count: 0,
    page: 1,
    rowsPerPage: 20,
  },
  sort: {
    column: "created_at",
    direction: "descend",
  },
}

const casesReducer: Reducer<ICasesState, TCasesAction> = (prevState = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CASES_REQUEST:
      return {
        ...prevState,
        isCasesLoading: true,
      }
    case ActionTypes.GET_CASES_SUCCESS:
      return {
        ...prevState,
        isCasesLoading: false,
        cases: action.payload.cases,
        pagination: {
          ...prevState.pagination,
          count: action.payload.count,
        },
      }
    case ActionTypes.GET_CASES_FAIL:
      return {
        ...prevState,
        isCasesLoading: false,
      }
    case ActionTypes.UPDATE_FILTER:
      return {
        ...prevState,
        filter: action.payload,
      }
    case ActionTypes.UPDATE_PAGINATION:
      return {
        ...prevState,
        pagination: {
          ...prevState.pagination,
          page: action.payload.page,
          rowsPerPage: action.payload.rowsPerPage,
        },
      }
    case ActionTypes.UPDATE_SORT:
      return {
        ...prevState,
        sort: action.payload,
      }
    case ActionTypes.GET_CURRENT_CASE_REQUEST:
      return {
        ...prevState,
        isCurrentCaseLoading: true,
      }
    case ActionTypes.GET_CURRENT_CASE_SUCCESS:
      return {
        ...prevState,
        isCurrentCaseLoading: false,
        currentCase: action.payload,
      }
    case ActionTypes.GET_CURRENT_CASE_FAIL:
      return {
        ...prevState,
        isCurrentCaseLoading: false,
      }
    default:
      return prevState;
  }
}

export default casesReducer;

import { FC } from "react";

import { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";

import { ICase } from "store/cases/cases.types";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { getCasesSelector } from "store/cases/cases.selector";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";

import "./CasesMobileTable.scss";

const columns: ColumnsType<ICase> = [
  {
    title: "Case",
    dataIndex: "custom_id",
    key: "custom_id",
    render: (value, item) => <p className={ item.status === "open" ? "active" : "" }>{ value }</p>,
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
    render: (value, item) => <p className={ item.status === "open" ? "active" : "" }>{ value }</p>,
  },
]

const CasesMobileTable: FC = () => {
  const navigate = useNavigate();
  const { isAdmin } = useAppSelector(getUserSelector);
  const { cases, isCasesLoading } = useAppSelector(getCasesSelector);

  return (
    <Loading isLoading={ isCasesLoading } isColored height={ 200 }>
      <Table
        className="cases-table mobile"
        columns={ columns.filter((col) => col.key !== (isAdmin ? "severity" : "customer")) }
        dataSource={ cases }
        rowKey={ (item) => item.id }
        pagination={ false }
        onRow={ (record) => ({
          onClick: () => navigate(RouteLinks.PROTECTED.CASES.CASE + "/" + record.id),
        }) }
      />
    </Loading>
  )
}

export default CasesMobileTable;

import { FC, useEffect, useMemo, useState } from "react";

import { Col, Form, Input, Row, DatePicker, Select, Radio, Button, message } from "antd";
import dayjs from "dayjs";
import debounce from "lodash.debounce";

import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { getCasesSelector } from "store/cases/cases.selector";
import { ICasesFilter, Severity } from "store/cases/cases.types";
import { getSeverityTitle } from "services/title.service";
import { updateCasesFilter, updateCasesPagination, updateCasesSort } from "store/cases/cases.actions";
import { getCases } from "store/cases/cases.thunks";
import searchIcon from "data/icons/search.svg";
import filterIcon from "data/icons/filter.svg";
import api from "services/api.service";
import { initialState } from "store/cases/cases.reducer";

import "./Filter.scss";

const severities = [
  {
    value: null,
    label: "All",
  },
  {
    value: Severity.CRITICAL,
    label: getSeverityTitle(Severity.CRITICAL),
  },
  {
    value: Severity.MAJOR,
    label: getSeverityTitle(Severity.MAJOR),
  },
  {
    value: Severity.NORMAL,
    label: getSeverityTitle(Severity.NORMAL),
  },
  {
    value: Severity.INFO,
    label: getSeverityTitle(Severity.INFO),
  },
]

interface IFilter extends ICasesFilter {
  date: any[]
}

const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const { isAdmin, isMobile } = useAppSelector(getUserSelector);
  const { filter, pagination } = useAppSelector(getCasesSelector);
  const [ isFilterOpen, setIsFilterOpen ] = useState(true);
  const [ clients, setClients ] = useState<{ value: string, label: string }[]>([]);

  const getClients = async (search: string) => {
    try {
      const response = await api.getCustomers(search);

      setClients([ { value: "all", label: "All" }, ...response.data.results.map((res) => ({ value: res.id.toString(), label: res.latin_name })) ]);
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  useEffect(() => {
    isAdmin && getClients("");

    return () => {
      dispatch(updateCasesFilter(initialState.filter));
      dispatch(updateCasesSort(initialState.sort));
      dispatch(updateCasesPagination(initialState.pagination));
    }
  }, [])

  const handleChangeFilter = (_: Partial<ICasesFilter>, values: IFilter) => {
    if (_.client && isAdmin) {
      getClients("");
    }

    dispatch(updateCasesFilter({
      search: values.search,
      date_from: values.date && values.date[ 0 ] ? dayjs(values.date[ 0 ]).format("YYYY-MM-DD") : "",
      date_to: values.date && values.date[ 1 ] ? dayjs(values.date[ 1 ]).format("YYYY-MM-DD") : "",
      client: values.client || "",
      severity: values.severity || null,
      type: values.type,
    }));
    dispatch(updateCasesPagination({ page: 1, rowsPerPage: pagination.rowsPerPage }))
  }

  const updateInfo = () => {
    dispatch(getCases());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <Form
      className={ `filter-wrapper ${ isMobile ? "mobile" : "" }` }
      id="cases-filter"
      layout="vertical"
      initialValues={ filter }
      onValuesChange={ (e, values) => {
        handleChangeFilter(e, values);
        debouncedResults();
      } }
    >
      { isMobile ? (
        <>
          <div className="mobile-search-wrapper">
            <Form.Item
              name="search"
            >
              <Input
                prefix={ <img src={ searchIcon } alt="" /> }
                placeholder="Search for Case"
              />
            </Form.Item>
            <Button
              className="filter-icon"
              icon={ <img src={ filterIcon } alt="" /> }
              type="text"
              onClick={ () => setIsFilterOpen(!isFilterOpen) }
            />
          </div>
          { isFilterOpen && (
            <div className="mobile-filter">
              <Form.Item
                label="Date"
                name="date"
              >
                <DatePicker.RangePicker style={ { width: "100%" } } placeholder={ [ "Start Date", "End Date" ] } />
              </Form.Item>
              { isAdmin ? (
                <Form.Item
                  label="Client"
                  name="client"
                >
                  <Select
                    options={ clients }
                    showSearch
                    onSearch={ (value) => getClients(value) }
                    filterOption={ false }
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Severity"
                  name="severity"
                >
                  <Select
                    options={ severities }
                  />
                </Form.Item>
              ) }
              <Form.Item name="type" style={ { marginTop: "30px" } }>
                <Radio.Group>
                  <Radio value="all">All</Radio>
                  <Radio value="open">Open</Radio>
                  <Radio value="closed">Closed</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          ) }
        </>
      ) : (
        <Row gutter={ 24 }>
          <Col span={ 8 }>
            <Form.Item
              label="Search for Case"
              name="search"
            >
              <Input
                placeholder="Search"
                prefix={ <img src={ searchIcon } alt="" /> }
              />
            </Form.Item>
          </Col>
          <Col span={ 5 }>
            <Form.Item
              label="Date"
              name="date"
            >
              <DatePicker.RangePicker style={ { width: "100%" } } placeholder={ [ "Start Date", "End Date" ] } />
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            { isAdmin ? (
              <Form.Item
                label="Client"
                name="client"
              >
                <Select
                  options={ clients }
                  showSearch
                  onSearch={ (value) => getClients(value) }
                  filterOption={ false }
                />
              </Form.Item>
            ) : (
              <Form.Item
                label="Severity"
                name="severity"
              >
                <Select
                  options={ severities }
                />
              </Form.Item>
            ) }
          </Col>
          <Col span={ 5 } style={ { minWidth: "240px" } }>
            <Form.Item name="type" style={ { marginTop: "30px" } }>
              <Radio.Group>
                <Radio value="all">All</Radio>
                <Radio value="open">Open</Radio>
                <Radio value="close">Closed</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      ) }
    </Form>
  )
}

export default Filter;

import { FC, useEffect, useState } from "react";

import { ColumnsType } from "antd/es/table";
import { Button, Table, Tag, Tooltip } from "antd";

import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import { IContractEquipment } from "store/contracts/contracts.types";
import { Loading } from "components/common";
import "components/cases/CasesTable/CasesTable.scss";
import { getSupportTypeTitle } from "services/title.service";
import { updateContractEquipmentsSort } from "store/contracts/contracts.actions";
import { getContractEquipments, getExtraContractEquipments } from "store/contracts/contracts.thunks";
import { extraLimit } from "services/data.service";
import { CreateCaseForm } from "components/cases";

import "./ContractEquipmentsTable.scss";

const ContractEquipmentsTable: FC = () => {
  const {
    isEquipmentsLoading,
    equipments,
    equipmentsPagination,
    equipmentsSort,
    isExtraEquipmentsLoading,
  } = useAppSelector(getContractsSelector);
  const dispatch = useAppDispatch();
  const [ expandedRowKey, setExpandedRowKey ] = useState<number | null>(null);

  const [ updateState, setUpdateState ] = useState(false);
  useEffect(() => setUpdateState(!updateState), [ isEquipmentsLoading ])

  const columns: ColumnsType<IContractEquipment> = [
    {
      title: "",
      dataIndex: "number",
      key: "number",
      width: 40,
      render: (value, item, index) => item.serial_number === "add_more"
        ? <Button type="text" onClick={ () => dispatch(getExtraContractEquipments()) } disabled={ isExtraEquipmentsLoading }>+ Show more</Button>
        : <p className="equipment-number">{ index + 1 + (equipmentsPagination.page - 1) * equipmentsPagination.rowsPerPage }.</p>,
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 7 : 1,
        style: { textAlign: item.serial_number === "add_more" ? "center" : "initial" },
      }),
    },
    {
      title: "Product number",
      dataIndex: "product_number",
      key: "product_number",
      render: (value, item) => item.serial_number === "add_more" ? null : <p className="active">{ item.product.product_number }</p>,
      sortOrder: equipmentsSort.column === "product_number" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "product_number",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        id: "contract-equipments-header-cell",
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "product_number",
            direction: equipmentsSort.column === "product_number" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value, item) => item.serial_number === "add_more" ? null : <Tooltip title={ item.product.description }><p className="active thin">{ item.product.description }</p></Tooltip>,
      sortOrder: equipmentsSort.column === "description" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "description",
      showSorterTooltip: false,
      className: `description-table-cell`,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "description",
            direction: equipmentsSort.column === "description" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Serial Number",
      dataIndex: "serial_number",
      key: "serial_number",
      width: 190,
      className: `description-table-cell serial-number`,
      render: (value, item) => item.serial_number === "add_more" ? null : <Tooltip title={ value }><p className="active thin">{ value }</p></Tooltip>,
      sortOrder: equipmentsSort.column === "serial_number" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "serial_number",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "serial_number",
            direction: equipmentsSort.column === "serial_number" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
      }),
    },
    {
      title: "Start Date",
      dataIndex: "date_begin",
      key: "date_begin",
      sortOrder: equipmentsSort.column === "date_begin" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "date_begin",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "date_begin",
            direction: equipmentsSort.column === "date_begin" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
        style: { minWidth: "110px" },
      }),
    },
    {
      title: "End Date",
      dataIndex: "date_end",
      key: "date_end",
      sortOrder: equipmentsSort.column === "date_end" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "date_end",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "date_end",
            direction: equipmentsSort.column === "date_end" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
        style: { minWidth: "110px" },
      }),
    },
    {
      title: "Support Type",
      dataIndex: "support_type",
      key: "support_type",
      width: 130,
      render: (value, item) => item.serial_number === "add_more" ? null : <Tag className={ item.is_pending ? "pending" : `active ${ value }` }>{ getSupportTypeTitle(value, true, item.is_pending) }</Tag>,
      sortOrder: equipmentsSort.column === "support_type" ? equipmentsSort.direction : undefined,
      sorter: equipmentsSort.column === "support_type",
      showSorterTooltip: false,
      onHeaderCell: () => ({
        onClick: () => {
          dispatch(updateContractEquipmentsSort({
            column: "support_type",
            direction: equipmentsSort.column === "support_type" ? equipmentsSort.direction === "ascend" ? "descend" : "ascend" : "ascend",
          }))
          dispatch(getContractEquipments())
        },
      }),
      onCell: (item) => ({
        colSpan: item.serial_number === "add_more" ? 0 : 1,
      }),
    },
  ]

  useEffect(() => {
    const table = document.getElementById("contract-equipment-table");
    if (table) {
      const body: any = table.getElementsByClassName("ant-table-body");

      if (body.length > 0) {
        body[ 0 ].onscroll = (e: any) => {
          const tbody: any = table.getElementsByClassName("ant-table-tbody");
          if (tbody.length > 0) {
            if (tbody[ 0 ].offsetHeight === e.target.scrollTop + e.target.offsetHeight) {
              dispatch(getExtraContractEquipments());
            }
          }
        };
      }
    }
  }, [ isEquipmentsLoading ])

  return (
    <Loading isLoading={ isEquipmentsLoading } isColored isFullWidth height={ 200 }>
      <Table
        className="cases-table slim-table"
        id="contract-equipment-table"
        dataSource={ (equipments.length >= extraLimit || ((equipmentsPagination.page - 1) * extraLimit + equipments.length) === equipmentsPagination.count) ? equipments : [ ...equipments, { serial_number: "add_more" } as IContractEquipment ] }
        columns={ columns }
        pagination={ false }
        scroll={ { y: window.innerHeight - 303 - (document.getElementById("contract-equipments-header-cell")?.offsetHeight || 0) } }
        tableLayout="auto"
        rowClassName={ (item) => item.id === expandedRowKey ? "expanded" : "" }
        rowKey={ (item) => item.id }
        expandable={ {
          expandRowByClick: true,
          expandedRowRender: (item) => (
            <div className="expanded-menu">
              <CreateCaseForm serial_number={ item.serial_number } id={ item.id } />
            </div>
          ),
          onExpand: (isExpanded, item) => setExpandedRowKey(isExpanded ? item.id : null),
          showExpandColumn: false,
          expandedRowKeys: expandedRowKey !== null ? [ expandedRowKey ] : [],
        } }
      />
    </Loading>
  )
}

export default ContractEquipmentsTable;

import { useEffect } from "react";

import { Layout } from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";

import {
  AuthorizationPage,
  WelcomePage,
  ResetPasswordPage,
  RegistrationPage,
  CasesPage,
  DownloadsPage,
  ListEquipmentPage,
  ContractsPage,
  CurrentContractPage,
  UserManagementPage,
  NotFoundPage,
  ResetPasswordConfirmPage,
  StoragePage,
  CurrentCasePage,
  ProductsPage,
  MobileOopsPage,
} from "pages";
import { TopBar, NavBar } from "components";
import { RouteLinks } from "services/router.service";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { FileForm, DownloadCards, DownloadsFooter, DownloadsHeader, DownloadsTable } from "components/downloads";
import { getDownloadsSelector } from "store/downloads/downloads.selector";
import { parseJwt } from "services/data.service";
import { authorizeUserSuccess, setIsAdmin, setIsMobile } from "store/user/user.actions";
import { getNewClientsCount } from "store/userManagement/userManagement.thunks";
import { getUserData } from "store/user/user.thunks";

import "./App.scss";

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loc = useLocation();
  const { isAuthorized, isMobile, isAdmin } = useAppSelector(getUserSelector);
  const { dataDisplayType } = useAppSelector(getDownloadsSelector);

  useEffect(() => {
    dispatch(setIsMobile(window.outerWidth <= 480))

    const handleSetIsMobile = () => {
      dispatch(setIsMobile(window.outerWidth <= 480))
    }

    window.addEventListener("resize", handleSetIsMobile)

    return () => {
      window.removeEventListener("resize", handleSetIsMobile);
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem("AUTH_TOKEN");
    if (token !== null && !isAuthorized) {
      const infoFromToken = parseJwt(token);
      if (infoFromToken.exp > new Date().getTime()/1000) {
        dispatch(authorizeUserSuccess());
        dispatch(setIsAdmin(infoFromToken.is_spo));
      } else {
        localStorage.removeItem("AUTH_TOKEN");
      }
    }

    if (token !== null && isAuthorized) {
      const infoFromToken = parseJwt(token);
      dispatch(setIsAdmin(infoFromToken.is_spo));
      loc.pathname === "/" && navigate(RouteLinks.PROTECTED.CASES.MAIN);
      dispatch(getUserData());
      (isAdmin || infoFromToken.is_spo) && dispatch(getNewClientsCount());
    }
  }, [ isAuthorized ])

  return (
    <Layout className={ `App ${ isAuthorized ? "authenticated" : "" } ${ isMobile ? "mobile" : "" }` }>
      { isAuthorized && !isMobile && <NavBar /> }
      <Layout style={ { background: "none" } }>
        <TopBar />
        <Content className="App-inner-content">
          <Routes>
            { isAuthorized ? (
              <>
                <Route path={ RouteLinks.PROTECTED.LIST_EQUIPMENT } element={ isMobile ? <MobileOopsPage /> : <ListEquipmentPage /> } />
                <Route path={ RouteLinks.PROTECTED.CASES.MAIN } element={ <CasesPage /> } />
                <Route path={ RouteLinks.PROTECTED.CASES.CASE + "/:caseId" } element={ <CurrentCasePage /> } />
                <Route path={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN } element={ isMobile ? <MobileOopsPage /> : <ContractsPage /> } />
                <Route path={ RouteLinks.PROTECTED.CONTRACTS_ROUTES.CONTRACT + "/:contractId" } element={ isMobile ? <MobileOopsPage /> : <CurrentContractPage /> } />
                <Route path={ RouteLinks.PROTECTED.DOWNLOADS } element={ isMobile ? <MobileOopsPage /> : <DownloadsPage /> }>
                  <Route
                    path={ RouteLinks.PROTECTED.DOWNLOAD_ROUTES.MAIN }
                    element={ isMobile ? <MobileOopsPage /> :
                      <>
                        <DownloadsHeader />
                        { dataDisplayType === "table" ? <DownloadsTable /> : <DownloadCards /> }
                        <DownloadsFooter />
                      </>
                    }
                  />
                  <Route
                    path={ RouteLinks.PROTECTED.DOWNLOAD_ROUTES.ADD_FILE }
                    element={ isMobile ? <MobileOopsPage /> : <FileForm /> }
                  />
                  <Route
                    path={ RouteLinks.PROTECTED.DOWNLOAD_ROUTES.FILE + "/:fileId" }
                    element={ isMobile ? <MobileOopsPage /> : <FileForm /> }
                  />
                </Route>
                { isAdmin && (
                  <>
                    <Route
                      path={ RouteLinks.PROTECTED.USER_MANAGEMENT }
                      element={ isMobile ? <MobileOopsPage /> : <UserManagementPage /> }
                    />
                    <Route path={ RouteLinks.PROTECTED.STORAGE } element={ isMobile ? <MobileOopsPage /> : <StoragePage /> } />
                    <Route path={ RouteLinks.PROTECTED.PRODUCTS } element={ isMobile ? <MobileOopsPage /> : <ProductsPage /> } />
                  </>
                ) }
              </>
            ) : (
              <>
                <Route path={ RouteLinks.AUTH } element={ <AuthorizationPage /> } />
                <Route path={ RouteLinks.MAIN } element={ <WelcomePage /> } />
                <Route path={ RouteLinks.RESET_PASSWORD } element={ <ResetPasswordPage /> } />
                <Route path={ RouteLinks.SIGN_UP } element={ <RegistrationPage /> } />
                <Route path={ RouteLinks.RESET_PASSWORD_CONFIRM } element={ <ResetPasswordConfirmPage /> } />
              </>
            ) }
            <Route path="*" element={ <NotFoundPage /> } />
          </Routes>
        </Content>
      </Layout>
      { isAuthorized && isMobile && <NavBar /> }
    </Layout>
  );
}

export default App;

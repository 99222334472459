import { FC, useEffect, useState } from "react";

import { Button, Input, message, Modal, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { IClient } from "store/userManagement/userManagement.types";
import "components/cases/CasesTable/CasesTable.scss";
import { useAppDispatch, useAppSelector } from "store";
import { getUserManagementSelector } from "store/userManagement/userManagement.selector";
import editIcon from "data/icons/edit.svg";
import { Loading } from "components/common";
import api from "services/api.service";
import { updateClient, updateClientsSort } from "store/userManagement/userManagement.actions";
import { getClients, getNewClientsCount } from "store/userManagement/userManagement.thunks";
import { formatPhoneNumber, getClientRoleTitle } from "services/data.service";

import "./UserManagementTable.scss";

const UserManagementTable: FC = () => {
  const dispatch = useAppDispatch();
  const { clients, filter, isClientsLoading, sort } = useAppSelector(getUserManagementSelector);

  const [ customers, setCustomers ] = useState<{ value: string, label: string }[]>([]);

  const getCustomers = async (search: string) => {
    try {
      const response = await api.getCustomers(search);

      setCustomers([ ...response.data.results.map((res) => ({ value: res.id.toString(), label: res.latin_name })) ]);
    } catch (e: any) {
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  useEffect(() => {
    getCustomers("");
  }, [])

  const [ editingClient, setEditingClient ] = useState<IClient | null>(null);
  const [ isEditingClientLoading, setIsEditingClientLoading ] = useState(false);
  useEffect(() => setEditingClient(null), [ filter ])

  const [ clientAcceptingId, setClientAcceptingId ] = useState<string | null>(null);
  const [ clientBlockingId, setClientBlockingId ] = useState<string | null>(null);

  const [ acceptClient, setAcceptClient ] = useState<IClient | null>(null);

  const [ updateState, setUpdateState ] = useState(false);
  useEffect(() => setUpdateState(!updateState), [ isClientsLoading ])

  const handleSaveClient = async () => {
    if (editingClient) {
      try {
        setIsEditingClientLoading(true);
        await api.editClient(editingClient);
        dispatch(updateClient(editingClient));
        setIsEditingClientLoading(false);
        setEditingClient(null);
        dispatch(getClients());
      } catch (e: any) {
        setIsEditingClientLoading(false);
        message.error(e.response.data.error || e.error || "Something went wrong!");
      }
    }
  }

  const handleAcceptClient = async (client: IClient) => {
    try {
      setClientAcceptingId(client.id);
      await api.acceptClient(client.id);
      setClientAcceptingId(null);
      filter.type === "new" && dispatch(getNewClientsCount());
      dispatch(getClients());
    } catch (e: any) {
      setClientAcceptingId(null);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const handleBlockClient = async (client: IClient) => {
    try {
      setClientBlockingId(client.id);
      await api.blockClient(client.id);
      setClientBlockingId(null);
      dispatch(getClients());
    } catch (e: any) {
      setClientBlockingId(null)
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const handleCloseModal = () => {
    setAcceptClient(null);
  }

  const columns: ColumnsType<IClient> = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (value) => filter.type === "blocked" ? value : (
        <p className="active thin">{ value }</p>
      ),
      width: "9%",
      // sortOrder: sort.column === "first_name" ? sort.direction : undefined,
      // sorter: sort.column === "first_name",
      // showSorterTooltip: false,
      // onHeaderCell: () => ({
      //   id: "user-management-header-cell",
      //   onClick: () => {
      //     dispatch(updateClientsSort({
      //       column: "first_name",
      //       direction: sort.column === "first_name" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
      //     }))
      //     dispatch(getClients())
      //   },
      // }),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: "9%",
      render: (value) => filter.type === "blocked" ? value : (
        <p className="active thin">{ value }</p>
      ),
      // sortOrder: sort.column === "last_name" ? sort.direction : undefined,
      // sorter: sort.column === "last_name",
      // showSorterTooltip: false,
      // onHeaderCell: () => ({
      //   onClick: () => {
      //     dispatch(updateClientsSort({
      //       column: "last_name",
      //       direction: sort.column === "last_name" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
      //     }))
      //     dispatch(getClients())
      //   },
      // }),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "16%",
      render: (value) => filter.type !== "new" ? value : (
        <p className="active thin">{ value }</p>
      ),
      // sortOrder: sort.column === "email" ? sort.direction : undefined,
      // sorter: sort.column === "email",
      // showSorterTooltip: false,
      // onHeaderCell: () => ({
      //   onClick: () => {
      //     dispatch(updateClientsSort({
      //       column: "email",
      //       direction: sort.column === "email" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
      //     }))
      //     dispatch(getClients())
      //   },
      // }),
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      width: "14%",
      render: (value, item) => editingClient && editingClient.id === item.id ? (
        <Input
          className="edit-info-input"
          value={ editingClient.phone_number }
          onChange={ (e) => setEditingClient({ ...editingClient, phone_number: formatPhoneNumber(e.target.value) }) }
        />
      ) : value,
      // sortOrder: sort.column === "phone_number" ? sort.direction : undefined,
      // sorter: sort.column === "phone_number",
      // showSorterTooltip: false,
      // onHeaderCell: () => ({
      //   onClick: () => {
      //     dispatch(updateClientsSort({
      //       column: "phone_number",
      //       direction: sort.column === "phone_number" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
      //     }))
      //     dispatch(getClients())
      //   },
      // }),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: "11%",
      render: (value, item) => editingClient && editingClient.id === item.id ? (
        <Select
          className="edit-info-select"
          value={ editingClient.role }
          placeholder="Select role"
          onChange={ (e) => setEditingClient({ ...editingClient, role: e, customer: e === "customer_user" || e === "customer_admin" ? editingClient?.customer : null }) }
          options={ [
            { value: "provider_admin", label: getClientRoleTitle("provider_admin") },
            { value: "provider_staff", label: getClientRoleTitle("provider_staff") },
            { value: "provider_engineer", label: getClientRoleTitle("provider_engineer") },
            { value: "customer_admin", label: getClientRoleTitle("customer_admin") },
            { value: "customer_user", label: getClientRoleTitle("customer_user") },
          ] }
        />
      ) : value === null ? "-/-" : getClientRoleTitle(value),
      // sortOrder: sort.column === "role" ? sort.direction : undefined,
      // sorter: sort.column === "role",
      // showSorterTooltip: false,
      // onHeaderCell: () => ({
      //   onClick: () => {
      //     dispatch(updateClientsSort({
      //       column: "role",
      //       direction: sort.column === "role" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
      //     }))
      //     dispatch(getClients())
      //   },
      // }),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (value, item) => editingClient && editingClient.id === item.id ? (editingClient.role === "customer_admin" || editingClient.role === "customer_user") ? (
        <Select
          className="edit-info-select extra-width"
          value={ editingClient.customer }
          showSearch
          placeholder="Select customer"
          onSearch={ (value) => getCustomers(value) }
          onChange={ (e) => setEditingClient({ ...editingClient, customer: e }) }
          options={ customers }
          filterOption={ false }
        />
      ) : null : (item.role === "customer_admin" || item.role === "customer_user") ? item.customer === null ? "-/-" : item.customer.latin_name : null,
      // sortOrder: sort.column === "customer" ? sort.direction : undefined,
      // sorter: sort.column === "customer",
      // showSorterTooltip: false,
      // onHeaderCell: () => ({
      //   onClick: () => {
      //     dispatch(updateClientsSort({
      //       column: "customer",
      //       direction: sort.column === "customer" ? sort.direction === "ascend" ? "descend" : "ascend" : "ascend",
      //     }))
      //     dispatch(getClients())
      //   },
      // }),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: filter.type === "new" ? 228 : filter.type === "all" ? 109 : 120,
      render: (_, item) => {
        if (editingClient && editingClient.id === item.id) {
          return (
            <div className="buttons-wrapper">
              <Button type="primary" onClick={ handleSaveClient } disabled={ isEditingClientLoading }>
                <Loading isLoading={ isEditingClientLoading } width={ 32 }>
                  Save
                </Loading>
              </Button>
              <Button onClick={ () => setEditingClient(null) } disabled={ isEditingClientLoading }>Cancel</Button>
            </div>
          )
        }
        switch (filter.type) {
          case "new":
            return (
              <div className="buttons-wrapper">
                <Button
                  className="icon-button"
                  type="text"
                  icon={ <img src={ editIcon } alt="" /> }
                  onClick={ () => setEditingClient(item) }
                />
                <Button
                  type="primary"
                  onClick={ () => setAcceptClient(item) }
                  disabled={ item.role === null || ((item.role === "customer_admin" || item.role === "customer_user") && item.customer === null) }
                >
                  <Loading isLoading={ clientAcceptingId === item.id } width={ 46 }>
                    Accept
                  </Loading>
                </Button>
                <Button
                  onClick={ () => handleBlockClient(item) }
                  disabled={ clientAcceptingId === item.id || clientBlockingId === item.id }
                >
                  <Loading isLoading={ clientBlockingId === item.id } width={ 35.7 }>
                    Block
                  </Loading>
                </Button>
              </div>
            )
          case "all":
            return (
              <div className="buttons-wrapper">
                <Button
                  className="icon-button"
                  type="text"
                  icon={ <img src={ editIcon } alt="" /> }
                  onClick={ () => setEditingClient(item) }
                />
                <Button onClick={ () => handleBlockClient(item) } disabled={ clientBlockingId === item.id }>
                  <Loading isLoading={ clientBlockingId === item.id } width={ 36 }>
                    Block
                  </Loading>
                </Button>
              </div>
            )
          case "blocked":
            return (
              <div className="buttons-wrapper">
                <Button
                  className="icon-button"
                  type="text"
                  icon={ <img src={ editIcon } alt="" /> }
                  onClick={ () => setEditingClient(item) }
                />
                <Button
                  type="primary"
                  onClick={ () => setAcceptClient(item) }
                  disabled={ item.role === null || ((item.role === "customer_admin" || item.role === "customer_user") && item.customer === null) }
                >
                  <Loading isLoading={ clientAcceptingId === item.id } width={ 47 }>
                    Accept
                  </Loading>
                </Button>
              </div>
            )
        }
      },
    },
  ]

  return (
    <Loading isLoading={ isClientsLoading } isColored isFullWidth height={ 200 }>
      <Table
        className="cases-table user-management-table"
        columns={ columns }
        dataSource={ clients }
        pagination={ false }
        scroll={ { y: window.innerHeight - 183 - (document.getElementById("user-management-header-cell")?.offsetHeight || 0) } }
        tableLayout="auto"
      />
      <Modal
        className="user-accept-modal-wrapper"
        open={ acceptClient !== null }
        onCancel={ handleCloseModal }
        footer={ null }
      >
        { acceptClient && (
          <>
            <h4 className="modal-title">
              Mail about taking permission to SDP portal will be sent to{ " " }
              <span>
                { acceptClient.first_name } { acceptClient.last_name }.
              </span>
            </h4>
            <div className="modal-info">
              { acceptClient.customer !== null && (<p className="customer">{ acceptClient.customer.latin_name }</p>) }
              <p className="role">{ getClientRoleTitle(acceptClient.role!) }</p>
            </div>
            <p className="question">Accept user?</p>
            <div className="action-wrapper">
              <Button
                type="primary"
                onClick={ () => {
                  handleAcceptClient(acceptClient);
                  setAcceptClient(null);
                } }
              >
                Yes
              </Button>
              <Button onClick={ () => setAcceptClient(null) }>No</Button>
            </div>
          </>
        ) }
      </Modal>
    </Loading>
  )
}

export default UserManagementTable;

import { FC } from "react";

import { Layout } from "antd";

import { CaseForm } from "components/common";
import { useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";

import "./WelcomePage.scss";

const WelcomePage: FC = () => {
  const { isMobile } = useAppSelector(getUserSelector);

  return (
    <Layout className={ `welcome-page-wrapper ${ isMobile ? "mobile" : "" }` }>
      <h1>Welcome to Support Portal</h1>
      <Layout className="form-wrapper">
        <CaseForm />
      </Layout>
    </Layout>
  )
}

export default WelcomePage;

import { ChangeEvent, FC, useEffect, useMemo } from "react";

import { Input, Tabs, TabsProps } from "antd";
import debounce from "lodash.debounce";

import { useAppDispatch, useAppSelector } from "store";
import searchIcon from "data/icons/search.svg";
import { getUserManagementSelector } from "store/userManagement/userManagement.selector";
import { updateClientsFilter, updateClientsPagination, updateClientsSort } from "store/userManagement/userManagement.actions";
import { getClients } from "store/userManagement/userManagement.thunks";
import { initialState } from "store/userManagement/userManagement.reducer";

const UserManagementFilter: FC = () => {
  const dispatch = useAppDispatch();
  const { filter, newClientsCount, pagination } = useAppSelector(getUserManagementSelector);

  useEffect(() => {
    return () => {
      dispatch(updateClientsFilter(initialState.filter));
      dispatch(updateClientsSort(initialState.sort));
      dispatch(updateClientsPagination(initialState.pagination.page, initialState.pagination.rowsPerPage));
    }
  }, [])

  const tabs: TabsProps[ "items" ] = [
    {
      key: "new",
      label: (
        <span style={ { display: "flex" } }>
          New
          { newClientsCount > 0 && (
            <p className="clients-count-value">
              { newClientsCount }
            </p>
          ) }
        </span>
      ),
    },
    {
      key: "all",
      label: "All users",
    },
    {
      key: "blocked",
      label: "Blocked",
    },
  ]

  const handleChangeType = (type: string) => {
    dispatch(updateClientsFilter({
      ...filter,
      type: type as "new" | "all" | "blocked",
    }))
    dispatch(updateClientsPagination(1, pagination.rowsPerPage));
    dispatch(getClients());
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateClientsFilter({
      ...filter,
      search: e.target.value,
    }))
    dispatch(updateClientsPagination(1, pagination.rowsPerPage));
  }

  const updateInfo = () => {
    dispatch(getClients());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <div className="contracts-header-wrapper">
      <Tabs items={ tabs } activeKey={ filter.type } onChange={ handleChangeType } />
      <Input
        value={ filter.search }
        prefix={ <img src={ searchIcon } alt="" /> }
        placeholder="Search"
        onChange={ (e) => {
          handleChangeSearch(e);
          debouncedResults();
        } }
        className="search-input"
      />
    </div>
  )
}

export default UserManagementFilter;

import { INotification, IUser } from "./user.types";

export const ActionTypes = {
  AUTHORIZE_USER_REQUEST: "[USER] AUTHORIZE USER REQUEST",
  AUTHORIZE_USER_SUCCESS: "[USER] AUTHORIZE USER SUCCESS",
  AUTHORIZE_USER_FAIL: "[USER] AUTHORIZE USER FAIL",
  GET_USER_DATA_REQUEST: "[USER] GET USER DATA REQUEST",
  GET_USER_DATA_SUCCESS: "[USER] GET USER DATA SUCCESS",
  GET_USER_DATA_FAIL: "[USER] GET USER DATA FAIL",
  LOGOUT_USER: "[USER] LOGOUT USER",
  SET_IS_ADMIN: "[USER] SET IS ADMIN",
  GET_NOTIFICATIONS_REQUEST: "[USER] GET NOTIFICATIONS REQUEST",
  GET_NOTIFICATIONS_SUCCESS: "[USER] GET NOTIFICATIONS SUCCESS",
  GET_NOTIFICATIONS_FAIL: "[USER] GET NOTIFICATIONS FAIL",
  SET_IS_MOBILE: "[USER] SET IS MOBILE",
  UPDATE_PHONE_NUMBER: "[USER] UPDATE PHONE NUMBER",
} as const;

export const authorizeUserRequest = () => ({
  type: ActionTypes.AUTHORIZE_USER_REQUEST,
})

export const authorizeUserSuccess = () => ({
  type: ActionTypes.AUTHORIZE_USER_SUCCESS,
})

export const authorizeUserFail = () => ({
  type: ActionTypes.AUTHORIZE_USER_FAIL,
})

export const logoutUser = () => ({
  type: ActionTypes.LOGOUT_USER,
})

export const setIsAdmin = (isAdmin: boolean) => ({
  type: ActionTypes.SET_IS_ADMIN,
  payload: isAdmin,
})

export const getNotificationsRequest = () => ({
  type: ActionTypes.GET_NOTIFICATIONS_REQUEST,
})

export const getNotificationsSuccess = (notifications: INotification[]) => ({
  type: ActionTypes.GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const getNotificationsFail = () => ({
  type: ActionTypes.GET_NOTIFICATIONS_FAIL,
})

export const setIsMobile = (isMobile: boolean) => ({
  type: ActionTypes.SET_IS_MOBILE,
  payload: isMobile,
})

export const updatePhoneNumber = (phone: string) => ({
  type: ActionTypes.UPDATE_PHONE_NUMBER,
  payload: phone,
})

export const getUserDataRequest = () => ({
  type: ActionTypes.GET_USER_DATA_REQUEST,
})

export const getUserDataSuccess = (user: IUser) => ({
  type: ActionTypes.GET_USER_DATA_SUCCESS,
  payload: user,
})

export const getUserDataFail = () => ({
  type: ActionTypes.GET_USER_DATA_FAIL,
})

import { ChangeEvent, FC, useEffect, useMemo } from "react";

import { Input, Tabs } from "antd";
import debounce from "lodash.debounce";

import { useAppDispatch, useAppSelector } from "store";
import { getProductsSelector } from "store/products/products.selector";
import { Loading } from "components/common";
import { updateProductsFilter, updateProductsPagination } from "store/products/products.actions";
import { getProducts } from "store/products/products.thunks";
import searchIcon from "data/icons/search.svg";
import "components/contracts/ContractsHeader";

const ProductsHeader: FC = () => {
  const dispatch = useAppDispatch();
  const { filter, categories, isProductCategoriesLoading } = useAppSelector(getProductsSelector);

  const handleChangeCategory = (category: string) => {
    dispatch(updateProductsFilter({
      category: category === "all" ? null : +category,
    }))
    dispatch(updateProductsPagination(1));
    dispatch(getProducts());
  }

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateProductsFilter({
      search: e.target.value,
    }))
  }

  const updateInfo = () => {
    dispatch(updateProductsPagination(1));
    dispatch(getProducts());
  }

  const debouncedResults = useMemo(() => {
    return debounce(updateInfo, 400);
  }, [])

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  return (
    <div className="contracts-header-wrapper">
      <Loading isLoading={ isProductCategoriesLoading } isColored height={ 43 }>
        <Tabs
          items={ [ { key: "all", label: "All" }, ...categories.map((item) => ({ key: item.id.toString(), label: item.name })) ] }
          activeKey={ filter.category === null ? "all" : filter.category.toString() }
          onChange={ handleChangeCategory }
        />
        <Input
          value={ filter.search }
          prefix={ <img src={ searchIcon } alt="" /> }
          placeholder="Search"
          className="search-input"
          onChange={ (e) => {
            handleChangeSearch(e);
            debouncedResults()
          } }
        />
      </Loading>
    </div>
  )
}

export default ProductsHeader;

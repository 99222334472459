import { FC, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { RouteLinks } from "services/router.service";
import backIcon from "data/icons/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "store";
import { getContract, getContractEquipments } from "store/contracts/contracts.thunks";
import { ContractEquipmentsFilter, ContractEquipmentsTable, ContractInfo, ContractEquipmentsPagination } from "components/contracts";
import { getContractsSelector } from "store/contracts/contracts.selector";

import "./CurrentContractPage.scss";

const CurrentContractPage: FC = () => {
  const navigate = useNavigate();
  const { contractId } = useParams();
  const dispatch = useAppDispatch();
  const { isCurrentContractLoading } = useAppSelector(getContractsSelector);

  useEffect(() => {
    if (contractId) {
      dispatch(getContract(contractId));
      dispatch(getContractEquipments(+contractId));
    }
  }, [])

  return (
    <div className="default-page-wrapper current-contract-page-wrapper">
      <Button className="back-button" type="link" onClick={ () => navigate(RouteLinks.PROTECTED.CONTRACTS_ROUTES.MAIN) }>
        <img src={ backIcon } alt="" />
        Back
      </Button>
      <ContractInfo />
      { !isCurrentContractLoading && <ContractEquipmentsFilter /> }
      <ContractEquipmentsTable />
      <ContractEquipmentsPagination />
    </div>
  )
}

export default CurrentContractPage;

import { FC, useState } from "react";

import { Button, Input, message } from "antd";

import { Loading } from "components/common";
import { useAppDispatch, useAppSelector } from "store";
import { getContractsSelector } from "store/contracts/contracts.selector";
import editIcon from "data/icons/edit.svg";
import { updateCurrentContractAlias } from "store/contracts/contracts.actions";
import api from "services/api.service";

import "./ContractInfo.scss";

const ContractInfo: FC = () => {
  const dispatch = useAppDispatch();
  const { isCurrentContractLoading, currentContract } = useAppSelector(getContractsSelector);
  const [ isInfoEditing, setIsInfoEditing ] = useState(false);
  const [ info, setInfo ] = useState("");
  const [ isInfoLoading, setIsInfoLoading ] = useState(false);

  const handleStartEditInfo = () => {
    setInfo(currentContract?.information || "");
    setIsInfoEditing(true);
  }

  const handleCompleteInfo = async () => {
    if (currentContract) {
      try {
        setIsInfoLoading(true);

        await api.updateContractInformation(currentContract.id, info);

        dispatch(updateCurrentContractAlias(info));
        setIsInfoLoading(false);
        setIsInfoEditing(false);
        setInfo("");
      } catch (e: any) {
        setIsInfoLoading(false);
        message.error(e.response.data.error || e.error || "Something went wrong!");
      }
    }
  }

  const handleEndEditInfo = () => {
    setIsInfoEditing(false);
    setInfo("");
  }

  return (
    <Loading isLoading={ isCurrentContractLoading } isFullWidth isColored height={ 150 }>
      { currentContract && (
        <div className="contract-info-wrapper">
          <div className="contract-info-item">
            <p className="contract-info-title">Location :</p>
            <p className="contract-info-value">
              { currentContract.locations.split(" / ")
                .map((item, index, array) => (<>{ item }{ index !== array.length - 1 && (<span className="separator">/</span>) }</>))
              }
            </p>
          </div>
          <div className="contract-info-item">
            <p className="contract-info-title">Add information :</p>
            { isInfoEditing ? (
              <div className="contract-info-input-wrapper">
                <Input
                  className="contract-info-input"
                  value={ info }
                  onChange={ (e) => setInfo(e.target.value) }
                  onClick={ (e) => e.stopPropagation() }
                />
                <div className="contract-info-input-buttons">
                  <Loading isLoading={ isInfoLoading } isColored width={ 50 }>
                    <Button onClick={ handleCompleteInfo } type="primary">
                      Save
                    </Button>
                  </Loading>
                  <Button onClick={ handleEndEditInfo } disabled={ isInfoLoading }>
                    Cancel
                  </Button>
                </div>
              </div>
              ) : currentContract.information === "" ? (
                <p className="contract-add-info-button" onClick={ handleStartEditInfo }>
                  + Add information
                </p>
              ) : (
              <p className="contract-info-value">
                { currentContract.information }
                <img onClick={ handleStartEditInfo } src={ editIcon } alt="" />
              </p>
            ) }
          </div>
        </div>
      ) }
    </Loading>
  )
}

export default ContractInfo;

import { FC, useEffect, useState } from "react";

import { Button, Form, Input, message, Tag } from "antd";
import { useLocation } from "react-router-dom";

import userIcon from "data/icons/user.svg";
import emailIcon from "data/icons/email.svg";
import phoneIcon from "data/icons/phone.svg";
import addFileIcon from "data/icons/add-file.svg";
import attachedFileIcon from "data/icons/attached-file.svg";
import closeIcon from "data/icons/close.svg";
import { formatPhoneNumber } from "services/data.service";
import { useAppDispatch, useAppSelector } from "store";
import { getUserSelector } from "store/user/user.selector";
import { ICaseEquipment, Severity } from "store/cases/cases.types";
import api from "services/api.service";
import { getSupportTypeTitle } from "services/title.service";
import { ICaseForOpen } from "services/interface.service";
import { Loading } from "components/common";
import { RouteLinks } from "services/router.service";
import { getCases } from "store/cases/cases.thunks";

import "./CaseForm.scss";

const severities = [
  {
    value: 0,
    label: "Info",
  },
  {
    value: 1,
    label: "Normal",
  },
  {
    value: 2,
    label: "Major",
  },
  {
    value: 3,
    label: "Critical",
  },
]

const initialValues: Partial<ICaseForOpen> = {
  subject: "",
  text: "",
  serial_number: "",
  author_name: "",
  severity: Severity.INFO,
  author_email: "",
  author_phone_number: "",
}

interface IProps {
  afterEvent?: () => void;
  serial_number?: string;
  id?: number;
}

const CaseForm: FC<IProps> = ({ afterEvent, serial_number = "", id }) => {
  const [ form ] = Form.useForm();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isAuthorized, isAdmin, user } = useAppSelector(getUserSelector);

  const [ stateBool, forceUpdate ] = useState(false);
  const [ files, setFiles ] = useState<File[]>([]);
  const [ caseEquipment, setCaseEquipment ] = useState<ICaseEquipment | null>(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ additionalEmails, setAdditionalEmails ] = useState<string[]>([]);

  useEffect(() => {
    serial_number !== "" && findEquipment(serial_number);
    form.setFieldValue("serial_number", serial_number);
    if (isAuthorized && !isAdmin && user) {
      form.setFieldValue("author_name", user.first_name + " " + user.last_name)
      form.setFieldValue("author_email", user.email);
      form.setFieldValue("author_phone_number", formatPhoneNumber(user.phone_number));
    }
  }, [])

  const handleCompleteForm = async (values: ICaseForOpen) => {
    try {
      setIsLoading(true);

      let attachments: number[] = [];

      await files.reduce(async (memo, file) => {
        await memo;
        const response = await api.uploadFile(file);

        attachments.push(response.id)
      }, Promise.resolve());

      await api.openCase({ ...values, email_cc: additionalEmails, attachments });

      setIsLoading(false);
      afterCompleteEvent();
    } catch (e: any) {
      setIsLoading(false);
      message.error(e.response.data.error || e.error || "Something went wrong!");
    }
  }

  const afterCompleteEvent = () => {
    form.setFieldsValue(initialValues);
    setFiles([]);
    setCaseEquipment(null);
    setAdditionalEmails([]);
    location.pathname === RouteLinks.PROTECTED.CASES.MAIN && dispatch(getCases());
    afterEvent && afterEvent();
  }

  const findEquipment = async (serial_number: string) => {
    if (serial_number !== "" && isAuthorized) {
      try {
        const response = await api.getEquipmentForCase(serial_number, id);

        setCaseEquipment(response.data);
      } catch (e: any) {
        setCaseEquipment(null);
      }
    } else {
      setCaseEquipment(null);
    }
  }

  return (
    <Form
      className="case-form"
      name="case-form"
      layout="vertical"
      autoComplete="off"
      form={ form }
      onFinish={ handleCompleteForm }
      initialValues={ initialValues }
    >
      <h3>Complete the form</h3>
      <Form.Item
        label="Subject"
        name="subject"
        rules={ [ { required: true, message: "Please input subject!" } ] }
      >
        <Input
          className="with-count"
          showCount
          maxLength={ 150 }
          placeholder="Add subject"
        />
      </Form.Item>
      <Form.Item
        label={
          <>
            Problem Description
            <label className="add-file-label">
              <img src={ addFileIcon } alt="" style={ { marginRight: "5px" } } />
              Add file
              <input
                type="file"
                style={ { display: "none" } }
                multiple
                onChange={ (e) => {
                  let result: File[] = [];
                  e.target.files && Object.keys(e.target.files).forEach((label) => {
                    result.push(e.target.files![ +label ])
                  })
                  setFiles([ ...files, ...result ])
                } }
              />
            </label>
          </>
        }
        name="text"
        className="form-with-button"
      >
        <Input.TextArea
          style={ { resize: "none", height: "58px" } }
        />
      </Form.Item>
      <div className="files-list">
        { files.map((file) => (
          <div key={ file.name } className="files-item">
            <img src={ attachedFileIcon } alt="" />
            { file.name }
            <div
              className="cross-file"
              onClick={ () => setFiles(files.filter((fl) => fl.name !== file.name)) }
            >
              <img src={ closeIcon } alt="" />
            </div>
          </div>
        )) }
      </div>
      <Form.Item
        label="Serial Number"
        name="serial_number"
      >
        <Input
          placeholder="Add serial number"
          onChange={ (e) => findEquipment(e.target.value) }
        />
      </Form.Item>
      { caseEquipment && (
        <div className="case-equipment-wrapper">
          <p className="case-equipment-description">{ caseEquipment.product.description }</p>
          <p className="case-equipment-client">{ caseEquipment.customer.latin_name }</p>
          <div className="case-equipment-dates-wrapper">
            <p className="case-equipment-dates">{ caseEquipment.date_begin } - { caseEquipment.date_end }</p>
            <Tag className={ `${ caseEquipment.is_pending ? "pending" : caseEquipment.support_type }` }>{ getSupportTypeTitle(caseEquipment.support_type, caseEquipment.is_active, caseEquipment.is_pending) }</Tag>
          </div>
        </div>
      ) }
      <Form.Item className="mg-btn-0">
        <Form.Item
          label="Contact Name"
          name="author_name"
          style={ { display: "inline-block", width: "calc(50% - 10px)", marginRight: "20px" } }
        >
          <Input
            placeholder="Name"
            prefix={ <img src={ userIcon } alt="" /> }
          />
        </Form.Item>
        <Form.Item
          label="Severity"
          name="severity"
          style={ { display: "inline-block", width: "calc(50% - 10px)" } }
          initialValue="info"
        >
          <div className="custom-radio-group">
            { severities.map((severity) => (
              <div
                className={ `custom-radio-item ${ (form.getFieldValue("severity") === undefined ? severity.value === 0 : form.getFieldValue("severity") === severity.value) ? "active" : "" }` }
                key={ severity.value }
                onClick={ () => {
                  form.setFieldsValue({ severity: severity.value })
                  forceUpdate(!stateBool);
                } }
              >
                <div className="custom-radio-button" />
                <p className="custom-radio-title">{ severity.label }</p>
              </div>
            )) }
          </div>
        </Form.Item>
      </Form.Item>
      <Form.Item className="mg-btn-0">
        <Form.Item
          className="mg-btn-0"
          label="Email"
          name="author_email"
          rules={ [ { required: true, message: "Please input your email!" }, { type: "email", message: "Please input correct email!" } ] }
          style={ { display: "inline-block", width: "calc(50% - 10px)", marginRight: "20px" } }
        >
          <Input
            placeholder="yourname@mail.com"
            prefix={ <img src={ emailIcon } alt="" /> }
          />
        </Form.Item>
        <Form.Item
          className="mg-btn-0"
          label="Phone"
          name="author_phone_number"
          style={ { display: "inline-block", width: "calc(50% - 10px)" } }
        >
          <Input
            placeholder="+375 (00) 000-00-00"
            prefix={ <img src={ phoneIcon } alt="" style={ { marginRight: "5px" } } /> }
            onChange={ (e) => form.setFieldsValue({ author_phone_number: formatPhoneNumber(e.target.value) }) }
          />
        </Form.Item>
      </Form.Item>
      { additionalEmails.length > 0 && (
        <Form.Item style={ { marginBottom: 0 } }>
          { additionalEmails.map((item: string, id: number) => (
            <Form.Item
              key={ id }
              className="mg-btn-0"
              label={ `Email ${ id + 2 }` }
              name={ `author_email-${ id + 2 }` }
              rules={ [ { type: "email", message: "Please input correct email!" } ] }
              style={ { display: "inline-block", width: "calc(50% - 10px)", marginRight: id % 2 === 0 ? "20px" : "0", marginTop: "15px" } }
            >
              <Input
                placeholder="yourname@mail.com"
                prefix={ <img src={ emailIcon } alt="" /> }
                value={ item }
                onChange={ (e) => setAdditionalEmails(additionalEmails.map((mail, index) => index === id ? e.target.value : mail)) }
              />
            </Form.Item>
          )) }
        </Form.Item>
      ) }
      { additionalEmails.length < 4 && (
        <Form.Item className="mg-btn-0">
          <Button className="add-email-button" type="text" onClick={ () => setAdditionalEmails([ ...additionalEmails, "" ]) }>
            + Add email
          </Button>
        </Form.Item>
      ) }
      <Form.Item className="mg-btn-0" style={ { marginTop: "25px" } }>
        <Button
          className="big-btn"
          type="primary"
          htmlType="submit"
          disabled={ isLoading }
        >
          <Loading isLoading={ isLoading } width={ 81 } height={ 25 }>
            Open Case
          </Loading>
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CaseForm;

import { IContract, IContractEquipment, IContractEquipmentsFilter, IContractsFilter, IShortContract } from "./contracts.types";
import { ISorting } from "services/interface.service";

export const ActionTypes = {
  GET_CONTRACTS_REQUEST: "[CONTRACTS] GET CONTRACTS REQUEST",
  GET_CONTRACTS_SUCCESS: "[CONTRACTS] GET CONTRACTS SUCCESS",
  GET_CONTRACTS_FAIL: "[CONTRACTS] GET CONTRACTS FAIL",
  UPDATE_CONTRACTS_FILTER: "[CONTRACTS] UPDATE CONTRACTS FILTER",
  UPDATE_CONTRACTS_PAGINATION: "[CONTRACTS] UPDATE CONTRACTS PAGINATION",
  UPDATE_CONTRACTS_SORT: "[CONTRACTS] UPDATE CONTRACTS SORT",
  GET_CURRENT_CONTRACT_REQUEST: "[CONTRACTS] GET CURRENT CONTRACT REQUEST",
  GET_CURRENT_CONTRACT_SUCCESS: "[CONTRACTS] GET CURRENT CONTRACT SUCCESS",
  GET_CURRENT_CONTRACT_FAIL: "[CONTRACTS] GET CURRENT CONTRACT FAIL",
  GET_CONTRACT_EQUIPMENTS_REQUEST: "[CONTRACTS] GET CONTRACT EQUIPMENTS REQUEST",
  GET_CONTRACT_EQUIPMENTS_SUCCESS: "[CONTRACTS] GET CONTRACT EQUIPMENTS SUCCESS",
  GET_CONTRACT_EQUIPMENTS_FAIL: "[CONTRACTS] GET CONTRACT EQUIPMENTS FAIL",
  CLEAR_CURRENT_CONTRACT: "[CONTRACTS] CLEAR CURRENT CONTRACT",
  UPDATE_ALIAS: "[CONTRACTS] UPDATE ALIAS",
  UPDATE_EQUIPMENTS_FILTER: "[CONTRACTS] UPDATE EQUIPMENTS FILTER",
  UPDATE_EQUIPMENTS_PAGINATION: "[CONTRACTS] UPDATE EQUIPMENTS PAGINATION",
  UPDATE_EQUIPMENTS_SORT: "[CONTRACTS] UPDATE EQUIPMENTS SORT",
  UPDATE_CURRENT_CONTRACT_ALIAS: "[CONTRACTS] UPDATE CURRENT CONTRACT ALIAS",
  GET_EXTRA_CONTRACT_EQUIPMENTS_REQUEST: "[CONTRACTS] GET EXTRA CONTRACT EQUIPMENTS REQUEST",
  GET_EXTRA_CONTRACT_EQUIPMENTS_SUCCESS: "[CONTRACTS] GET EXTRA CONTRACT EQUIPMENTS SUCCESS",
  GET_EXTRA_CONTRACT_EQUIPMENTS_FAIL: "[CONTRACTS] GET EXTRA CONTRACT EQUIPMENTS FAIL",
} as const;

export const getContractsRequest = () => ({
  type: ActionTypes.GET_CONTRACTS_REQUEST,
})

export const getContractsSuccess = (contracts: IShortContract[], count: number) => ({
  type: ActionTypes.GET_CONTRACTS_SUCCESS,
  payload: { contracts, count },
})

export const getContractsFail = () => ({
  type: ActionTypes.GET_CONTRACTS_FAIL,
})

export const updateContractsFilter = (filter: IContractsFilter) => ({
  type: ActionTypes.UPDATE_CONTRACTS_FILTER,
  payload: filter,
})

export const updateContractsPagination = (page: number, rowsPerPage: number) => ({
  type: ActionTypes.UPDATE_CONTRACTS_PAGINATION,
  payload: { page, rowsPerPage },
})

export const getCurrentContractRequest = () => ({
  type: ActionTypes.GET_CURRENT_CONTRACT_REQUEST,
})

export const getCurrentContractSuccess = (contract: IContract) => ({
  type: ActionTypes.GET_CURRENT_CONTRACT_SUCCESS,
  payload: contract,
})

export const getCurrentContractFail = () => ({
  type: ActionTypes.GET_CURRENT_CONTRACT_FAIL,
})

export const getContractEquipmentsRequest = () => ({
  type: ActionTypes.GET_CONTRACT_EQUIPMENTS_REQUEST,
})

export const getContractEquipmentsSuccess = (equipments: IContractEquipment[], count: number) => ({
  type: ActionTypes.GET_CONTRACT_EQUIPMENTS_SUCCESS,
  payload: { equipments, count },
})

export const getContractEquipmentsFail = () => ({
  type: ActionTypes.GET_CONTRACT_EQUIPMENTS_FAIL,
})

export const clearCurrentContract = () => ({
  type: ActionTypes.CLEAR_CURRENT_CONTRACT,
})

export const updateAlias = (contract: IShortContract, alias: string) => ({
  type: ActionTypes.UPDATE_ALIAS,
  payload: { contract, alias },
})

export const updateEquipmentsFilter = (filter: IContractEquipmentsFilter) => ({
  type: ActionTypes.UPDATE_EQUIPMENTS_FILTER,
  payload: filter,
})

export const updateEquipmentsPagination = (pagination: { page: number, rowsPerPage: number }) => ({
  type: ActionTypes.UPDATE_EQUIPMENTS_PAGINATION,
  payload: pagination,
})

export const updateContractsSort = (sort: ISorting) => ({
  type: ActionTypes.UPDATE_CONTRACTS_SORT,
  payload: sort,
})

export const updateContractEquipmentsSort = (sort: ISorting) => ({
  type: ActionTypes.UPDATE_EQUIPMENTS_SORT,
  payload: sort,
})

export const updateCurrentContractAlias = (alias: string) => ({
  type: ActionTypes.UPDATE_CURRENT_CONTRACT_ALIAS,
  payload: alias,
})

export const getExtraContractEquipmentsRequest = () => ({
  type: ActionTypes.GET_EXTRA_CONTRACT_EQUIPMENTS_REQUEST,
})

export const getExtraContractEquipmentsSuccess = (items: IContractEquipment[], count: number) => ({
  type: ActionTypes.GET_EXTRA_CONTRACT_EQUIPMENTS_SUCCESS,
  payload: { items, count },
})

export const getExtraContractEquipmentsFail = () => ({
  type: ActionTypes.GET_EXTRA_CONTRACT_EQUIPMENTS_FAIL,
})
